import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';
import Select from '../../../../../components/@v2/Select/Select';
import Layout from '../../../../../components/layouts/default';
import Assessments from '../../../../../constants/Assessments';
import {
  DefaultClientIntegration,
  DefaultTestValues,
  TestTypeValueConverter,
} from '../../../../../constants/ClientIntegration';
import useAssessment from '../../../../../hooks/useAssessment';
import AssessioForm from '../AssessioForm';
import RefappForm from '../RefappForm';
import AlvaLabsForm from '../AlvaLabsForm';
import SriForm from '../SriForm';
import AmsForm from '../AmsForm';
import HubertForm from '../HubertForm';

const AddIntegrationForm = () => {
  const { t } = useTranslation();
  const [integrationPartner, setIntegrationPartner] = useState(null);
  const { findTest } = useAssessment();

  const onIntegrationPartnerChange = ([selected]) => {
    setIntegrationPartner(selected);
  };

  const CLIENT_INTEGRATIONS_OPTIONS = useMemo(
    () =>
      DefaultClientIntegration.map((item) => ({
        ...item,
        key: item.id,
        value: item.id,
      })),
    [DefaultClientIntegration]
  );

  const COMPUTED_TESTS = useMemo(
    () =>
      DefaultTestValues.map((item) => {
        const testName = findTest(item.assessmentType);

        return {
          ...item,
          key: item.assessmentType,
          isCollapsible: DefaultTestValues.length > 1,
          defaultIsActive: item.isActive,
          testName:
            testName?.name ||
            testName?.label ||
            TestTypeValueConverter(item.assessmentType),
        };
      }),
    [DefaultTestValues]
  );

  return (
    <Layout
      pretitle={t('general.settings')}
      title={
        <>
          <p className="mb-0" style={{ color: 'inherit' }}>
            {t('settings.integrations.title')}
          </p>
        </>
      }
    >
      <div className="anim-table-delayed max-w-2xl w-full shadow-sm p-4 bg-white rounded-lg">
        <FormGroup>
          <Label>{t('settings.integrations.integration-partner')}</Label>
          <Select
            searchable
            searchKey="label"
            value={integrationPartner}
            onSelect={onIntegrationPartnerChange}
            defaultOptions={CLIENT_INTEGRATIONS_OPTIONS}
          />
        </FormGroup>
        {!!Assessments.refapp &&
          Assessments.refapp === integrationPartner?.id && <RefappForm />}
        {!!Assessments.assessio &&
          Assessments.assessio === integrationPartner?.id && (
            <AssessioForm
              initialValues={{ organizationId: '', tests: COMPUTED_TESTS }}
            />
          )}
        {!!Assessments.sri && Assessments.sri === integrationPartner?.id && (
          <SriForm />
        )}
        {!!Assessments.alvaLabs &&
          Assessments.alvaLabs === integrationPartner?.id && <AlvaLabsForm />}
        {!!Assessments.hubert &&
          Assessments.hubert === integrationPartner?.id && <HubertForm />}
        {!!Assessments.ams && Assessments.ams === integrationPartner?.id && (
          <AmsForm />
        )}
      </div>
    </Layout>
  );
};

export default AddIntegrationForm;
