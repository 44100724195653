/* eslint-disable no-return-assign */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Card, Col, Row, NavLink, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import { debounce, get, sortBy } from 'lodash';
import '../../assets/sass/candidates.sass';
import { useLocation } from 'react-router-dom';
import Icon from '../@v2/Icon';
import ModalFromRight from '../ModalFromRight';
import { setCandidatesWithKey, setFiltersWithKey } from '../../store/slices/settings.slice';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../store';
import { useApiRequestContext } from '../../context/@v2/ApiRequestContext';

const MainTabV2 = () => {
  const { t } = useTranslation();
  const apiRequestContext = useApiRequestContext();
  const location = useLocation();
  const { data, meta, setMeta } = apiRequestContext;
  const navContainer = useRef(null);
  const [thumbWidth, setThumbWidth] = useState(0);
  const [sliderWidth, setSliderWidth] = useState(0);
  const pipelines = useSelectorToolkit(({ settings }) => get(settings, 'position.pipelines')) || [];

  const rejectedCount = data?.rejectedPipelines?.reduce((accumulator, currentValue) => {
    return accumulator + (currentValue.rejectedCandidatesCount || 0);
  }, 0);

  const [modalDetails, setModalDetails] = useState(null);

  const sum = useMemo(() => {
    if (pipelines && pipelines.length) {
      return pipelines.reduce((accumulator, currentValue) => currentValue.candidatesCount + accumulator, 0);
    }

    return 0;
  }, [pipelines, rejectedCount]);

  if (!pipelines || !pipelines.length) return null;

  const candidatesFilter = useSelectorToolkit(({ settings }) => get(settings, 'filters.candidates'));
  const activePipelineStepId = get(candidatesFilter, 'pipelineStepId') || null;

  const selectTab = async (id = null, type = '') => {
    if (
      (id === candidatesFilter?.pipelineStepId && !type) ||
      (!candidatesFilter?.pipelineStepId && type === candidatesFilter?.status)
    ) {
      return;
    }

    const currentFilters = [...meta.filters].filter(
      (item) => item.key !== 'pipelineStepId' && item.key !== 'status' && item.key !== 'rejected'
    );

    if (id) {
      currentFilters.push(
        {
          key: 'pipelineStepId',
          value: id,
        },
      );

      if (candidatesFilter?.status) {
        currentFilters.push({
          key: 'status',
          value: 'Active',
        });
      }

      setMeta({
        ...meta,
        page: 1,
        filters: currentFilters,
      });

      dispatchToolkit(
        setFiltersWithKey({
          key: 'candidates',
          payload: {
            ...candidatesFilter,
            pipelineStepId: id,
            status: 'Active',
            rejected: false,
          },
        })
      );
    } else if (type === 'Rejected') {
      setMeta({
        page: 1,
        filters: [
          ...currentFilters,
          {
            key: 'status',
            value: type,
          },
          {
            key: 'rejected',
            value: true,
          },
        ],
      });

      dispatchToolkit(
        setFiltersWithKey({
          key: 'candidates',
          payload: {
            ...candidatesFilter,
            pipelineStepId: null,
            status: type,
            rejected: true,
          },
        })
      );
    } else {
      setMeta({
        page: 1,
        filters: currentFilters,
      });

      dispatchToolkit(
        setFiltersWithKey({
          key: 'candidates',
          payload: {
            ...candidatesFilter,
            pipelineStepId: null,
            status: null,
          },
        })
      );
    }

    dispatchToolkit(
      setCandidatesWithKey({
        key: 'unchecked',
        payload: [],
      })
    );

    if (!location.pathname.includes('/view')) {
      dispatchToolkit(
        setCandidatesWithKey({
          key: 'selected',
          payload: [],
        })
      );
    }

    dispatchToolkit(
      setCandidatesWithKey({
        key: 'active',
        payload: {},
      })
    );

    dispatchToolkit(
      setCandidatesWithKey({
        key: 'selectAll',
        payload: false,
      })
    );
  };

  const onResize = () => {
    if (navContainer.current) setSliderWidth(navContainer.current.clientWidth);

    let pipelineStepIndex = null;

    sortBy(pipelines, 'sortOrder').forEach((item, index) => {
      if ((item.candidates || item.candidateCount) > 0) {
        pipelineStepIndex = index;
      }
    });

    const navItems =
      navContainer && navContainer.current && navContainer.current.children ? [...navContainer.current.children] : [];

    if (pipelineStepIndex !== null) {
      setThumbWidth(
        navItems.reduce((accumulator, item, index) => {
          if (index <= pipelineStepIndex) {
            if (navItems.length - 1 === index) {
              return (accumulator += item.offsetWidth - 1);
            }

            return (accumulator += item.offsetWidth);
          }

          return accumulator;
        }, 0) + 1
      );
      return;
    }

    setThumbWidth(0);
  };

  useEffect(() => {
    if (pipelines && pipelines.length) {
      setTimeout(() => {
        onResize();
      }, 500);
    }
  }, [pipelines]);

  useEffect(() => {
    window.addEventListener('resize', debounce(onResize, 250));

    return () => {
      window.removeEventListener('resize', debounce(onResize, 250));
    };
  }, [onResize]);

  return (
    <>
      <Row className="d-flex align-items-center candidates-table mb-4">
        <Col className="d-flex align-items-center justify-content-between">
          <Card
            style={{
              marginBottom: 0,
              flex: 1,
            }}
          >
            <div className="candidates-nav-container">
              <ul className="candidates-tab nav nav-tabs" ref={navContainer}>
                {sortBy(pipelines, 'sortOrder').map((pipeline, index) => {
                  const { id, name, candidatesCount, rejectedCandidatesCount, instructionHtml } = pipeline;

                  const activeTab = parseInt(activePipelineStepId, 10) === id;

                  return (
                    <li
                      key={id}
                      className={classnames('nav-item', {
                        'nav-item--active': activeTab,
                        'pr-4': !!instructionHtml,
                      })}
                    >
                      <div
                        className={classnames(
                          'd-flex align-items-center justify-content-center',
                          activeTab ? 'active' : 'opacity-50'
                        )}
                      >
                        <NavLink
                          data-testid={`candidates-main-tab-${index}`}
                          className={classnames('flex-grow-1 text-center', {
                            'mr-2': !!instructionHtml,
                          })}
                          onClick={() => {
                            selectTab(id);
                          }}
                        >
                          <span className="text-black">{name}</span>
                          <Badge color="secondary" pill>
                            {(candidatesCount - rejectedCandidatesCount) || 0}
                          </Badge>
                        </NavLink>
                        {!!instructionHtml && (
                          <>
                            <div
                              className="d-flex align-items-center justify-content-center"
                              onClick={() => {
                                setModalDetails(pipeline);
                              }}
                              id={`pipeline-steps-popover--${id}`}
                            >
                              <Icon name="info-square" type="fas" />
                            </div>
                            <UncontrolledTooltip
                              placement="bottom"
                              target={`pipeline-steps-popover--${id}`}
                              style={{ marginTop: -50 }}
                              className="pipeline-steps-popover"
                            >
                              {t('general.show-info')}
                            </UncontrolledTooltip>
                          </>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div className="slider" style={{ width: sliderWidth }}>
                <div className="thumb" style={{ width: thumbWidth }} />
              </div>
            </div>
          </Card>
          <Card className="mb-0 ml-4">
            <div className="candidates-nav-container">
              <ul className="candidates-tab nav nav-tabs">
                <li className="nav-item">
                  <NavLink
                    data-testid="candidates-main-tab-all"
                    className="flex items-center justify-center active"
                    onClick={() => {
                      selectTab(null, 'all');
                    }}
                  >
                    {t('candidates.main-tab.all')}
                    <Badge color="secondary" pill>
                      {sum || 0}
                    </Badge>
                  </NavLink>
                </li>
              </ul>
              <div className="slider">
                <div className="thumb" />
              </div>
            </div>
          </Card>
          <Card className="mb-0 ml-4">
            <div className="candidates-nav-container">
              <ul className="candidates-tab nav nav-tabs">
                <li className="nav-item">
                  <NavLink
                    data-testid="candidates-main-tab-rejected"
                    id="pptr-main-tab-rejected"
                    className="flex items-center justify-center active"
                    onClick={() => {
                      selectTab(null, 'Rejected');
                    }}
                  >
                    {t('candidates.table-header.rejected')}
                    <Badge color="secondary" pill>
                      {rejectedCount}
                    </Badge>
                  </NavLink>
                </li>
              </ul>
              <div className="slider">
                <div className="thumb" />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <ModalFromRight
        show={!!modalDetails}
        className="card-header--filter-modal"
        onClose={(state) => {
          if (!state) {
            setModalDetails(null);
          }
        }}
      >
        <div className="card-header--filter-modal-container p-4">
          <h2 className="mb-0">{get(modalDetails, 'pipelineStepName')}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: get(modalDetails, 'instructionHtml'),
            }}
          />
        </div>
      </ModalFromRight>
    </>
  );
};

export default MainTabV2;
