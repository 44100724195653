import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { get, uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import { Badge } from 'reactstrap';
import axios from 'axios';
import Table from '../../../components/@v2/Table/Table';
import Card from '../../../components/@v2/Card';
import { useAPI } from '../../../context/api';
import ApiRequestProvider, { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import Paginator from '../../../components/@v2/Paginator/Paginator';
import Search from '../../../components/@v2/Search/Search';
import Layout from '../../../components/layouts/candidates';
import MainTabV2 from '../../../components/candidates/MainTabV2';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../../store';
import { getCategory, log } from '../../../helpers/utils';
import StarRating from '../../../components/StarRating';
import i18n from '../../../i18n';
import Avatar from '../../../components/Avatar';
import {
  setActivePosition,
  setCandidatesSorts,
  setCandidatesWithKey,
  setFiltersWithKey,
} from '../../../store/slices/settings.slice';
import CandidatesFilter from './CandidatesFilter';
import { getImageServiceMediaUrl } from '../../../helpers/image';
import Loader from '../../../components/Loader';
import { getReadableDate } from '../../../helpers/date';
import { getCandidates } from '../../../store/api/candidates.api';

const SEARCH_KEY = 'name';
const publicUrl = global?.appConfig?.public_url || '';

const Candidates = () => {
  moment.locale(i18n.language);
  const { t } = useTranslation();
  const [positionAPI] = useAPI('position');
  const history = useHistory();
  const apiRequestContext = useApiRequestContext();
  const { loading, data, meta, setMeta } = apiRequestContext;
  const position = useSelectorToolkit(({ settings }) => settings.position);
  const candidatesFilters = useSelectorToolkit(({ settings }) => settings.filters.candidates);
  const candidatesSettings = useSelectorToolkit(({ settings }) => settings.candidates);
  const [isFetchingId, setIsFetchingId] = useState(null);
  const hasPosition = !!candidatesFilters?.positionId && !!(position?.id || position?.positionId);
  const onCandidateClick = async (item) => {
    if (isFetchingId) return;
    let newFilters = { ...candidatesFilters };

    try {
      dispatchToolkit(
        setCandidatesWithKey({
          key: 'fromPosition',
          payload: hasPosition,
        })
      );

      const name = meta?.filters?.find((filter) => filter.key === SEARCH_KEY)?.value;

      if (name && !hasPosition) {
        newFilters = {
          ...candidatesFilters,
        };
      }

      if (candidatesSettings?.selected?.length > 1 && candidatesSettings?.selected?.find((c) => c.id === item.id)) {
        dispatchToolkit(
          setFiltersWithKey({
            key: 'candidates',
            payload: newFilters,
          })
        );

        history.push(`/candidates/view/${item?.id}?page=${meta.page}`);
      } else {
        setIsFetchingId(item.id);

        if (!candidatesSettings?.selectAll || candidatesSettings?.unchecked?.find((c) => c.id === item.id)) {
          if (item?.positionId) {
            const postionItem = await positionAPI.getPosition(item.positionId);

            if (postionItem) {
              const pipelines = await positionAPI.getPipelineSteps(item.positionId);

              dispatchToolkit(
                setActivePosition({
                  ...postionItem,
                  pipelines,
                  activePipelineStepId: item.pipelineStepId,
                })
              );

              await dispatchToolkit(
                setFiltersWithKey({
                  key: 'candidates',
                  payload: {
                    ...newFilters,
                    positionId: item?.positionId || null,
                    pipelineStepId: item?.pipelineStepId || null,
                  },
                })
              );
            }
          }

          await dispatchToolkit(
            setCandidatesWithKey({
              key: 'selected',
              payload: [item],
            })
          );

          await dispatchToolkit(
            setCandidatesWithKey({
              key: 'unchecked',
              payload: [],
            })
          );

          await dispatchToolkit(
            setCandidatesWithKey({
              key: 'selectAll',
              payload: false,
            })
          );
        }

        history.push(`/candidates/view/${item?.id}?page=${meta.page}`);
        setIsFetchingId(null);
      }
    } catch (error) {
      log(error);
    }
  };

  const onSelectAll = (e) => {
    dispatchToolkit(
      setCandidatesWithKey({
        key: 'selected',
        payload: [],
      })
    );

    dispatchToolkit(
      setCandidatesWithKey({
        key: 'unchecked',
        payload: [],
      })
    );

    dispatchToolkit(
      setCandidatesWithKey({
        key: 'selectAll',
        payload: e.target.checked,
      })
    );
  };

  const onSelectCandidate = (state, candidate) => {
    if (state) {
      if (get(candidatesSettings, 'unchecked').length === 1) {
        dispatchToolkit(
          setCandidatesWithKey({
            key: 'selected',
            payload: [],
          })
        );
      } else {
        dispatchToolkit(
          setCandidatesWithKey({
            key: 'selected',
            payload: uniqBy([...get(candidatesSettings, 'selected'), candidate], (e) => e.id),
          })
        );
      }

      dispatchToolkit(
        setCandidatesWithKey({
          key: 'unchecked',
          payload: get(candidatesSettings, 'unchecked').filter((c) => c.id !== candidate.id),
        })
      );

      return;
    }

    dispatchToolkit(
      setCandidatesWithKey({
        key: 'unchecked',
        payload:
          get(candidatesSettings, 'selected').length === 1
            ? []
            : uniqBy([...get(candidatesSettings, 'unchecked'), candidate], (e) => e.id),
      })
    );

    dispatchToolkit(
      setCandidatesWithKey({
        key: 'selected',
        payload: get(candidatesSettings, 'selected').filter((c) => c.id !== candidate.id),
      })
    );
  };

  const createStatusBar = (pipelineStepId) => {
    const pipelineSteps = position.pipelines;
    const foundStep = pipelineSteps.findIndex(({ id }) => id === pipelineStepId);

    return (
      <div className="table-status-bar" style={{ width: pipelineSteps.length * 20 }}>
        <div
          className="bar-thumb"
          style={{
            width: foundStep >= 0 ? (foundStep + 1) * 20 : null,
          }}
        />
        <div className="bar-wrapper d-inline-flex">
          {pipelineSteps.map(({ id }) => (
            <div
              key={id}
              className={classNames('bar-item', {
                'bar-item--active': id === pipelineStepId,
              })}
            />
          ))}
        </div>
      </div>
    );
  };

  const createProgress = useCallback(
    (pipelineStepId) => {
      if (position.pipelines) {
        const foundPipeline = position.pipelines.find(({ id }) => id === pipelineStepId);

        if (foundPipeline) {
          return (
            <>
              <p style={{ marginBottom: 1 }}>{foundPipeline.pipelineStepName}</p>
              {createStatusBar(foundPipeline.id)}
            </>
          );
        }
      }

      return '-';
    },
    [position]
  );

  const headers = useMemo(() => {
    const DEFAULT_HEADERS = [
      {
        label: '',
        component: (
          <div>
            <input type="checkbox" onChange={onSelectAll} checked={candidatesSettings.selectAll} />
          </div>
        ),
        sort: false,
        width: 1,
        headerClassName: '!pr-0',
      },
      {
        label: 'general.name',
        key: 'name',
        sortKey: 'Name',
      },
      {
        label: '',
        sort: false,
      },
      {
        label: '',
        sort: false,
      },
      {
        label: 'general.rating',
        key: 'stars',
        sortKey: 'Rating',
      },
      {
        label: 'candidates.last-activity',
        key: 'previousActivity',
        sortKey: 'LastActivity',
      },
      {
        label: 'candidates.table.progress',
        key: 'currentStepSort',
      },
      {
        label: 'candidates.date-of-application',
        key: 'applicationDate',
        sortKey: 'DateOfApplication',
      },
    ];

    if (hasPosition) {
      DEFAULT_HEADERS.splice(4, 0, {
        label: 'general.category',
        key: 'category',
      });

      return DEFAULT_HEADERS;
    }

    DEFAULT_HEADERS.splice(4, 0, {
      label: 'general.location-name',
      key: 'locationName',
      sort: false,
    });

    DEFAULT_HEADERS.splice(4, 0, {
      label: 'general.position-name',
      key: 'positionName',
      sort: false,
    });

    return DEFAULT_HEADERS;
  }, [hasPosition, candidatesSettings?.selectAll]);

  useEffect(() => {
    dispatchToolkit(
      setFiltersWithKey({
        key: 'candidates',
        payload: meta?.filters?.reduce((accumulator, currentValue) => {
          if (accumulator[currentValue?.key]) {
            return accumulator;
          }

          return {
            ...accumulator,
            [currentValue?.key]: currentValue?.value,
          };
        }, {}),
      })
    );
  }, [meta?.filters]);

  return (
    <Layout>
      {candidatesFilters?.positionId && position?.pipelines && !!position?.pipelines?.length && <MainTabV2 />}
      <Card className="anim-table-delayed shadow-table !border !border-line-default">
        <Search
          placeholder={t('general.search')}
          resource={SEARCH_KEY}
          meta={meta}
          setMeta={setMeta}
          filter={<CandidatesFilter />}
          whitelist={['positionId', 'pipelineStepId']}
        />
        <Table
          isLoaded={!loading}
          items={data?.items || []}
          pageSize={meta.pageSize}
          activeSort={meta.sorts}
          headers={headers}
          setMeta={setMeta}
          renderBody={(item, index) => {
            const {
              id,
              name,
              category,
              stars,
              previousActivity,
              pipelineStepId,
              applicationDate,
              rejected,
              picture,
              positionName,
              locationName,
            } = item;

            const isCandidateSelected = !!candidatesFilters?.positionId
              ? candidatesSettings?.selected.find((candidate) => candidate.id === item.id)
              : null;

            const isCandidateNotSelected = candidatesSettings?.unchecked.find((candidate) => candidate.id === item.id);

            return (
              <tr key={`candidates-${index}-${id}`}>
                <td className="!pr-0">
                  <input
                    type="checkbox"
                    checked={!isCandidateNotSelected && (candidatesSettings.selectAll || !!isCandidateSelected)}
                    onChange={(e) => {
                      onSelectCandidate(e.target.checked, item);
                    }}
                  />
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => {
                      onCandidateClick(item);
                    }}
                    className="flex items-center justify-between w-full"
                    data-testid={`candidate-item-${index}`}
                  >
                    <div className="flex items-center justify-around flex-grow-1 relative">
                      <div className="flex items-center flex-grow-1">
                        <Avatar
                          src={getImageServiceMediaUrl({
                            ...picture,
                            name: picture?.id,
                          })}
                          size={32}
                        />
                        <span className="ml-3 flex-grow-1 text-left">{name}</span>
                      </div>
                    </div>
                  </button>
                </td>
                <td className="px-0">
                  <button
                    type="button"
                    onClick={() => {
                      onCandidateClick(item);
                    }}
                    className="flex items-center justify-between w-full"
                  >
                    <div className="flex items-center justify-around flex-grow-1 relative">
                      {rejected && (
                        <Badge color="danger" className="flex items-center justify-center">
                          {t('candidates.table-header.rejected')}
                        </Badge>
                      )}
                    </div>
                  </button>
                </td>
                <td className="px-0">
                  <button
                    type="button"
                    onClick={() => {
                      onCandidateClick(item);
                    }}
                    className="flex items-center justify-between w-full"
                  >
                    <div className="flex items-center justify-around flex-grow-1 relative">
                      {item.fromBoostPackage && isFetchingId !== id && (
                        <span className="w-6 h-6">
                          <img alt="WeSelect logo" src={`${publicUrl}/images/logo-new-trans.png`} />
                        </span>
                      )}
                      {isFetchingId === id && (
                        <Loader
                          size={30}
                          type="primary"
                          parentStyles={{
                            position: 'relative',
                          }}
                          customLoaderStyles={{
                            height: 30,
                            width: 30,
                          }}
                        />
                      )}
                    </div>
                  </button>
                </td>
                {hasPosition ? (
                  <td>{getCategory(category, 'badge')}</td>
                ) : (
                  <>
                    <td>{positionName}</td>
                    <td>{locationName}</td>
                  </>
                )}
                <td>
                  <StarRating rate={stars} />
                </td>
                <td>{getReadableDate(previousActivity, true)}</td>
                <td>{createProgress(pipelineStepId)}</td>
                <td>{getReadableDate(applicationDate)}</td>
              </tr>
            );
          }}
        />
        {!loading && !!(data?.candidates?.length || data?.items?.length) && (
          <div className="h-14 flex items-center bg-white">
            <Paginator
              pageCount={data?.totalCount / meta?.pageSize}
              initialPage={meta?.page - 1}
              onPageChange={(value) => {
                if (meta.page !== value) {
                  setMeta({
                    page: value,
                  });
                }
              }}
              disableInitialCallback
            />
          </div>
        )}
      </Card>
    </Layout>
  );
};

const CandidatesWrapper = () => {
  const [positionAPI] = useAPI('position');
  const position = useSelectorToolkit(({ settings }) => settings.position);
  const candidatesFilter = useSelectorToolkit(({ settings }) => settings.filters.candidates);
  const { status, positionId, pipelineStepId } = candidatesFilter;

  const defaultFilters = useMemo(() => {
    const filters = [];

    Object?.keys(candidatesFilter)?.forEach((item) => {
      if (candidatesFilter?.[item] || candidatesFilter?.[item] === false) {
        filters.push({
          key: item,
          condition: item === 'name' ? '@=' : '==',
          value: candidatesFilter?.[item],
        });
      }
    });

    return filters;
  }, [candidatesFilter]);

  const pipelineFilters = useMemo(() => {
    let newFilters = '';

    if (positionId) {
      newFilters = `positionId==${positionId}`;

      if (pipelineStepId) {
        if (newFilters) {
          newFilters += ',';
        }
        newFilters += `pipelineStepId==${pipelineStepId}`;
      }
    }

    return newFilters;
  }, [positionId, pipelineStepId]);

  const mergeFilters = (defaultFilters, newFilters) => {
    if (defaultFilters && newFilters) {
      return `${defaultFilters},${newFilters}`;
    }

    return newFilters || defaultFilters;
  };

  const rejectedPipelinesRef = useRef(null);

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source, ...rest) => {
        const resolvedPositionId = !!candidatesFilter?.positionId ? position?.id || position?.positionId : null;
        const newFilters = {
          positionId: resolvedPositionId,
        };

        const filters = rest?.[2] || [];

        filters.forEach((item) => {
          newFilters[item.key] = item.value;
        });

        const sort = {
          by: meta.sorts?.replace('-', ''),
          direction: meta.sorts.includes('-') ? 'desc' : 'asc',
        };

        await dispatchToolkit(setCandidatesSorts(meta.sorts));

        if (resolvedPositionId) {
          if (!rejectedPipelinesRef.current) {
            const rejectedPipelines = await positionAPI.getPipelineSteps(resolvedPositionId, {
              filters: 'status==Rejected',
            });

            rejectedPipelinesRef.current = rejectedPipelines;
          }
        } else {
          rejectedPipelinesRef.current = null;
        }

        const requests = [
          getCandidates(
            {
              filter: newFilters,
              page: {
                number: meta.page,
                size: meta.pageSize,
              },
              sort: meta.sorts ? sort : {},
            },
            {
              cancelToken: source.token,
            }
          ),
        ];

        if (resolvedPositionId) {
          requests.push(
            positionAPI.getPipelineSteps(resolvedPositionId, {
              filters: mergeFilters(pipelineFilters, status ? `status==${status}` : null),
            })
          );
        }

        const [data, pipelines] = await axios.all(requests);

        if (pipelines) {
          dispatchToolkit(
            setActivePosition({
              ...position,
              pipelines: pipelines || [],
            })
          );
        }

        dispatchToolkit(
          setCandidatesWithKey({
            key: 'totalCount',
            payload: data?.totalCount,
          })
        );

        dispatchToolkit(
          setCandidatesWithKey({
            key: 'data',
            payload: data?.items,
          })
        );

        return {
          ...data,
          rejectedPipelines: status !== 'Rejected' ? rejectedPipelinesRef.current : pipelines,
        };
      }}
      defaultFilters={defaultFilters}
      defaultSorts="-DateOfApplication"
    >
      <Candidates />
    </ApiRequestProvider>
  );
};

export default CandidatesWrapper;
