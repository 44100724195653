import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../../../../components/layouts/default';
import RectangleLoader from '../../../../../components/RectangleLoader';
import { CreateIntegrationsHrsOptions } from '../../../../../constants/ClientIntegration';
import ApiRequestProvider, {
  useApiRequestContext,
} from '../../../../../context/@v2/ApiRequestContext';
import { getIntegration } from '../../../../../store/api/client-integrations.api';
import HubertForm from '../HubertForm';

const ID = 'hubert';

const EditHubertForm = () => {
  const { t } = useTranslation();
  const { loading, data } = useApiRequestContext();
  const HRS_OPTIONS = CreateIntegrationsHrsOptions(t);

  return (
    <Layout
      pretitle={t('general.settings')}
      title={
        <div className="flex items-center gap-x-2">
          <p className="mb-0" style={{ color: '#95AAC9' }}>
            {t('settings.integrations.title')}
          </p>
          <p className="mb-0">|</p>
          <p className="mb-0 capitalize">{ID}</p>
        </div>
      }
    >
      {loading && <RectangleLoader />}
      {!loading && (
        <div className="anim-table-delayed max-w-2xl w-full shadow-sm p-4 bg-white rounded-lg">
          <HubertForm
            initialValues={{
              apiKey: data?.configModel?.apiKey,
              assessmentConfig: {
                ...data?.configModel?.assessmentConfig,
                reminderDelayHours:
                  HRS_OPTIONS?.find(
                    (item) =>
                      item?.value ===
                      data?.configModel?.assessmentConfig?.reminderDelayHours
                  ) || null,
              },
              id: data?.id,
              isActive: data?.isActive,
              type: data?.type,
            }}
          />
        </div>
      )}
    </Layout>
  );
};

const EditHubertFormWrapper = () => {
  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, token) => {
        const response = await getIntegration(ID, token);

        return response;
      }}
    >
      <EditHubertForm />
    </ApiRequestProvider>
  );
};

export default EditHubertFormWrapper;
