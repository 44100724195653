import { isNull, isNumber, isUndefined } from 'lodash';
import qs from 'query-string';
import GDPRResponse from '@ts/apicontracts/clients/gdpr/response';
import UpdateClientGdprSettingsCommand from '@ts/apicontracts/clients/gdpr/update-client-gdpr-settings-command';
import ClientConfigResponse from '@ts/apicontracts/clients/config/response';
import ClientSettingsResponse from '@ts/apicontracts/clients/settings/response';
import UpdateClientSettingsCommand from '@ts/apicontracts/clients/settings/update-client-settings-command';
import AppEventsSettingsResponse from '@ts/apicontracts/clients/app-events/response';
import UpdateAppEventsSettings from '@ts/apicontracts/clients/app-events/update-client-app-event-settings-command';
import PageSettingsResponse from '@ts/apicontracts/clients/page/response';
import UpdatePageSettingsCommand from '@ts/apicontracts/clients/page/update-client-page-settings-command';
import { api, AxiosResponseDirect } from '../../helpers/api-v2';

const resource = '/clients';

// TODO: use apicontracts instead
export type ClientCustomizeColorsValues = {
  imageBackgroundTitleColor: string | null;
  imageBackgroundLocationColor: string | null;
  imageBackgroundCTAButtonColor: string | null;
  imageBackgroundCTAButtonTextColor: string | null;
  imageBackgroundCTAButtonBorderColor: string | null;
  imageOverlayOpacity: any | null;
  imageOverlayColor: string | null;
  solidBackgroundColor: string | null;
  solidBackgroundTitleColor: string | null;
  solidBackgroundLocationColor: string | null;
  solidBackgroundCTAButtonColor: string | null;
  solidBackgroundCTAButtonTextColor: string | null;
  solidBackgroundCTAButtonBorderColor: string | null;
};

export type GetClientsExternalConfigResponse = {
  refappOptionsList: {
    id: string;
    label: string;
  }[];
  alvaLabsTestProfiles: {
    id: string;
    label: string;
  }[];
  hubertInterviewTemplates: {
    id: string;
    label: string;
  }[];
  sriOrderersList: {
    id: string;
    label: string;
  }[];
  sriRecipientsList: {
    id: string;
    label: string;
  }[];
};

export const getAppConfig = async (options = {}) => {
  const externalConfig = await api.get(`${resource}/config`, options);
  return externalConfig as unknown as AxiosResponseDirect<any>;
};

export const getExternalConfig = async (options = {}) => {
  const externalConfig = await api.get(`${resource}/config-external`, options);
  return externalConfig as unknown as AxiosResponseDirect<GetClientsExternalConfigResponse>;
};

export const getGDPRSettings = async (options = {}) => {
  const clients = await api.get(`${resource}/settings/gdpr`, options);

  return clients as unknown as AxiosResponseDirect<GDPRResponse>;
};

// TODO: missing in apicontracts
export const getClients = (queryParams = {}, options = {}) => {
  const clients = api.get(`${resource}?${qs.stringify(queryParams)}`, options);
  return clients;
};

export const getClientsConfig = async () => {
  const clientsConfig = await api.get(`${resource}/config`);
  return clientsConfig as unknown as AxiosResponseDirect<ClientConfigResponse>;
};

export const getClientSettings = async (options = {}) => {
  const settings = await api.get(`${resource}/settings`, options);
  return settings as unknown as AxiosResponseDirect<ClientSettingsResponse>;
};

export const updateClient = async (id, form) => {
  const response = await api.put(resource, form);
  return response;
};

export const deleteClient = async (id) => {
  const response = await api.delete(`${resource}/admin/${id}`);
  return response;
};

export const updateClientSettings = async (form: UpdateClientSettingsCommand) => {
  const response = await api.put(`${resource}/settings`, form);
  return response;
};

export const getClientMarketingSettings = async (id: string) => {
  const response = await api.get(`${resource}/${id}/settings/marketing`);
  return response as unknown as AxiosResponseDirect<ClientCustomizeColorsValues>;
};

export const updateClientMarketingSettings = async (id: string, form) => {
  const response = await api.put(`${resource}/${id}/settings/marketing`, form);
  return response as unknown as AxiosResponseDirect<ClientCustomizeColorsValues>;
};

export const previewtMarketingColorSettings = async (form) => {
  const response = await api.post(`${resource}/settings/marketing/preview-color-settings`, form);
  return response;
};

export const updateClientGdprSettings = async (form: UpdateClientGdprSettingsCommand) => {
  const gdprSettings = await api.put(`${resource}/settings/gdpr`, form);
  return gdprSettings;
};

// TODO: missing in apicontracts
export const createOrUpdateClient = async (values) => {
  try {
    const gdprDeleteDirectApplicationMonths = values?.settingsModel?.gdprDeleteDirectApplicationMonths;
    const parsedGdprDeleteDirectApplication = parseInt(gdprDeleteDirectApplicationMonths || 1, 10);
    const gdprDeleteSpontanenousApplicationMonths = values?.settingsModel.gdprDeleteSpontanenousApplicationMonths;
    const parsedGdprDeleteSpontaneousApplication = parseInt(gdprDeleteSpontanenousApplicationMonths || 1, 10);

    const siteType = values?.siteType?.value;
    const typeValue = values?.type?.value;

    const form = {
      ...values,
      id: values?.clientId,
      language: values?.language?.value || 'en-US',
      type: !isNull(typeValue) || !isUndefined(typeValue) ? parseInt(typeValue, 10) : null,
      siteType: isNumber(siteType) ? siteType : null,
      pageSettings: values.pageSettings,
      companyIndustries: values?.companyIndustry
        ? (values?.companyIndustry || []).map((item) => item.value)
        : values.companyIndustries,
      masterClientId: values?.masterClientId?.value || null,
    };

    const settings = {
      ...values.settingsModel,
      gdprDeleteDirectApplicationMonths: parsedGdprDeleteDirectApplication || 1,
      gdprDeleteSpontanenousApplicationMonths: parsedGdprDeleteSpontaneousApplication || 1,
      showRekryShop: values?.settingsModel?.showRekryShop?.value || false,
      facebookPageId: values?.settingsModel?.facebookPageId || window.appConfig?.fb_page_id || '',
      facebookAdAccountId: values?.settingsModel?.facebookAdAccountId || window.appConfig?.fb_ads_account_id || '',
      instagramAccountId: values?.settingsModel?.instagramAccountId || window.appConfig?.instagram_account_id || '',
    };

    form.clientSettings = settings;

    let response = null;

    if (form?.clientId) {
      response = await api.put(`${resource}/admin`, form);
    } else {
      response = await api.post(`${resource}/admin`, form);
    }

    return response;
  } catch (error) {
    throw error;
  }
};

export const getEventsSettings = async (options = {}) => {
  const settings = await api.get(`${resource}/settings/app-events`, options);
  return settings as unknown as AxiosResponseDirect<AppEventsSettingsResponse>;
};

export const updateEventsSettings = async (values: UpdateAppEventsSettings) => {
  const settings = await api.put(`${resource}/settings/app-events`, values);
  return settings;
};

export const getPageSettings = async (options = {}) => {
  const settings = await api.get(`${resource}/settings/page`, options);
  return settings as unknown as AxiosResponseDirect<PageSettingsResponse>;
};

export const updatePageSettings = async (form: UpdatePageSettingsCommand, options = {}) => {
  const settings = await api.put(`${resource}/settings/page`, form, options);
  return settings;
};

export const getAdminClients = async (params, options = {}) => {
  const clients = await api.post(`${resource}/admin/list`, params, options);

  return clients;
};

export const getAdminClientById = async (id: number, options = {}) => {
  const client = await api.get(`${resource}/admin/${id}`, options);

  return client;
};

// TODO: missing in apicontracts
export const getClient = async (isAdmin, id) => {
  if (isAdmin) {
    const client = await api.get(`${resource}/admin/${id}`);

    return {
      id,
      ...client,
    };
  }

  return { id };
};
