import moment from 'moment';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import axios from 'axios';
import { isArray, differenceBy } from 'lodash';
import Card from '../../../components/@v2/Card';
import Paginator from '../../../components/@v2/Paginator/Paginator';
import Search from '../../../components/@v2/Search/Search';
import Avatar from '../../../components/Avatar';
import CandidateName from '../../../components/candidates/CandidateName';
import CandidateButtons from '../../../components/candidates/CandidateButtons';
import MainTabV2 from '../../../components/candidates/MainTabV2';
import Icon from '../../../components/@v2/Icon';
import Layout from '../../../components/layouts/candidates';
import StarRating from '../../../components/StarRating';
import ApiRequestProvider, { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import { useAPI } from '../../../context/api';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../../store';
import { setActivePosition, setCandidatesWithKey, setFiltersWithKey } from '../../../store/slices/settings.slice';
import CandidatesFilter from './CandidatesFilter';
import CandidateViewList from './CandidateViewList';
import {
  getCandidateDetails as getCandidateDetailsThunk,
  setCandidateRating,
} from '../../../store/thunks/candidates.thunks';
import Tags from '../../../components/candidates/Tags';
import LinkedPositions from '../../../components/candidates/LinkedPositions';
import CandidateTabs from './CandidateTabs';
import { selectUser } from '../../../store/selectors/user.selector';
import { getImageServiceMediaUrl } from '../../../helpers/image';
import { log } from '../../../helpers/utils';
import { getCandidates } from '../../../store/api/candidates.api';

const publicUrl = global?.appConfig?.public_url || '';
const CandidateView = () => {
  const { t } = useTranslation();
  const position = useSelectorToolkit(({ settings }) => settings.position);
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const queryParams = useMemo(() => qs.parse(search), [search]);
  const { id: activeIdInUrl } = useParams();
  const { meta, setMeta, data, loading, reloadData } = useApiRequestContext();
  const candidatesSettings = useSelectorToolkit(({ settings }) => settings.candidates);
  const calendarSettings = useSelectorToolkit(({ settings }) => settings.calendar);
  const candidatesFilters = useSelectorToolkit(({ settings }) => settings.filters.candidates);
  const isMultipleSelected = candidatesSettings?.selected?.length >= 2 || candidatesSettings?.selectAll;
  const activeCandidate = {
    ...candidatesSettings?.selected?.[0],
    ...candidatesSettings?.active,
  };

  const onSelectAll = (e) => {
    dispatchToolkit(
      setCandidatesWithKey({
        key: 'selected',
        payload: !e.target.checked ? [data?.candidates.find((item) => item.id === parseInt(activeIdInUrl, 10))] : [],
      })
    );

    dispatchToolkit(
      setCandidatesWithKey({
        key: 'unchecked',
        payload: [],
      })
    );

    dispatchToolkit(
      setCandidatesWithKey({
        key: 'selectAll',
        payload: e.target.checked,
      })
    );
  };

  const getCandidateDetails = (id) => {
    dispatchToolkit(getCandidateDetailsThunk(id));
  };

  useEffect(() => {
    const checkCandidate = data?.candidates?.find((item) => item.id.toString() === activeIdInUrl);

    const candidateFromLinkedPosition = data?.candidates?.find(
      (item) => item.name === candidatesSettings?.previousCandidate?.name
    );

    if (!candidatesSettings?.selectAll && !candidatesSettings?.fromRedirect) {
      if (data?.candidates[0]) {
        const defaultCandidate = data?.candidates[0];
        // In cases where we come from a linked position, the candidateId needs reloading
        if (!checkCandidate) {
          const existingCandidate =
            candidateFromLinkedPosition?.positionId === defaultCandidate?.positionId
              ? candidateFromLinkedPosition
              : defaultCandidate;

          dispatchToolkit(
            setCandidatesWithKey({
              key: 'selected',
              payload: [existingCandidate],
            })
          );
          const candidateToLoad =
            candidateFromLinkedPosition?.positionId === defaultCandidate?.positionId
              ? existingCandidate
              : checkCandidate;

          history.push(`/candidates/view/${(candidateToLoad || defaultCandidate).id}`);
        }
      }
    }

    if (!loading && !data?.candidates?.length) {
      history.push('/candidates');
    }
  }, [
    loading,
    data?.candidates,
    candidatesSettings?.selectAll,
    candidatesSettings?.previousCandidate,
    candidatesSettings?.fromRedirect,
  ]);

  const latestCandidateRating = useMemo(() => {
    if (isArray(activeCandidate?.ratings)) {
      return [...activeCandidate?.ratings]?.pop()?.rate;
    }

    return 0;
  }, [activeCandidate?.ratings]);

  const selectedCandidateCount = useMemo(
    () => {
      if (data?.candidates && candidatesSettings?.unchecked) {
        return data?.candidates.length - candidatesSettings?.unchecked.length;
      }
      return 0;
    },
    [data?.candidates, candidatesSettings?.unchecked]);

  useEffect(() => {
    if (!candidatesSettings?.selectAll && candidatesSettings?.selected?.length === 1) {
      getCandidateDetails(activeIdInUrl);
    }
    if (candidatesSettings?.selectAll && selectedCandidateCount === 1) {
      const foundCandidate = differenceBy(data?.candidates || [], candidatesSettings?.unchecked || [], 'id');
      if (foundCandidate && foundCandidate[0]) {
        getCandidateDetails(foundCandidate[0]?.id);
        dispatchToolkit(
          setCandidatesWithKey({
            key: 'active',
            payload: foundCandidate[0],
          })
        );
      }
    }
  },
    [
      candidatesSettings?.selectAll,
      candidatesSettings?.selected,
      candidatesSettings?.unchecked,
      activeIdInUrl,
      selectedCandidateCount,
      data?.candidates
    ]
  );

  const user = useSelectorToolkit(selectUser);

  const userRating = useMemo(() => {
    if (activeCandidate?.ratings) {
      const foundItem = activeCandidate?.ratings.find((rating) => rating.userId === user?.userId);

      if (foundItem && foundItem.rate !== undefined && foundItem.rate !== null) {
        return {
          ...foundItem,
          value: foundItem.rate || 0,
        };
      }
    }

    return {
      value: 0,
    };
  }, [activeCandidate?.ratings]);

  const onCandidateRate = async (rate) => {
    try {
      let apiType = 'post';

      if (userRating.value === rate) {
        apiType = 'delete';
      } else if (userRating.id) {
        apiType = 'put';
      }

      await dispatchToolkit(
        setCandidateRating({
          params: {
            userId: user?.userId,
            ratingId: userRating?.id || 0,
            value: userRating?.value === rate ? 0 : rate,
            candidateId: activeCandidate?.id || activeIdInUrl,
          },
          type: apiType,
        })
      );

      reloadData();
      getCandidateDetails(activeIdInUrl);
    } catch (error) {
      log(error);
    }
  };

  useEffect(() => {
    dispatchToolkit(
      setFiltersWithKey({
        key: 'candidates',
        payload: meta?.filters?.reduce((accumulator, currentValue) => {
          if (accumulator[currentValue?.key]) {
            return accumulator;
          }

          return {
            ...accumulator,
            [currentValue?.key]: currentValue?.value,
          };
        }, {}),
      })
    );
  }, [meta?.filters]);

  const pageCount = Math.ceil(data?.totalCount / meta?.pageSize) || 0;

  return (
    <Layout testId="position">
      {position?.pipelines && !!position?.pipelines?.length && <MainTabV2 />}
      <Card
        className="anim-table-delayed shadow-table !border !border-line-default bg-white flex flex-col w-full"
        style={{
          minHeight: 'calc(100vh - 400px)',
        }}
      >
        <Search
          placeholder={t('general.search')}
          resource="name"
          meta={meta}
          setMeta={setMeta}
          filter={<CandidatesFilter />}
          whitelist={['positionId', 'pipelineStepId']}
        />
        <div className="flex border-t border-light-100 bg-white flex-grow overflow-x-scroll whitespace-nowrap">
          <div className="w-full min-w-[350px] max-w-[350px] flex flex-col flex-grow">
            <div className="checkall-input-box-container p-3 border-b border-r border-light-100 bg-light-blue-100 flex items-center gap-x-3">
              <input
                type="checkbox"
                onChange={onSelectAll}
                checked={candidatesSettings.selectAll}
                className="accent-checkbox-accent"
              />
              <span className="text-sm font-bold">{t('general.select-all')}</span>
            </div>
            <CandidateViewList loading={loading} candidates={data?.candidates || []} />
            {data?.totalCount > meta?.pageSize && (
              <div className="h-14 flex items-center bg-white border-t border-r border-light-100">
                <Paginator
                  pageCount={pageCount}
                  initialPage={(queryParams.page || meta?.page) - 1}
                  onPageChange={(value) => {
                    if (meta.page !== value) {
                      setMeta({
                        page: value,
                      });
                    }
                  }}
                  disableInitialCallback
                  pageRangeDisplayed={pageCount <= 4 ? 2 : 0}
                  marginPagesDisplayed={pageCount <= 4 ? 2 : 1}
                  withPageSize={false}
                />
              </div>
            )}
          </div>
          <div className="w-full min-w-[400px] flex-grow border-l border-light-100 p-5 candidate-card-v2 overflow-x-scroll whitespace-nowrap">
            {!loading && (
              <div className="flex justify-between flex-wrap gap-y-6 lgMax:mb-8 mb-4">
                <div className="flex">
                  {(!isMultipleSelected || selectedCandidateCount === 1) && (
                    <Avatar
                      src={getImageServiceMediaUrl({
                        ...activeCandidate?.picture,
                        name: activeCandidate?.picture?.id,
                        width: 500,
                      })}
                      size={120}
                      className="mr-4"
                    />
                  )}
                  <div>
                    <CandidateName details={candidatesSettings} selectedCandidateCount={selectedCandidateCount} activeCandidate={activeCandidate} />
                    {(!isMultipleSelected || selectedCandidateCount === 1) && (
                      <>
                        {[
                          {
                            key: 'id',
                            icon: 'hash',
                          },
                          {
                            key: 'dateOfBirth',
                            icon: 'birthday-cake',
                          },
                          {
                            key: 'email',
                            icon: 'envelope',
                          },
                          {
                            key: 'phoneNumber',
                            icon: 'phone',
                          },
                          {
                            key: 'linkedInProfileUrl',
                            icon: 'linkedin',
                          },
                        ].map(({ key, icon }) => {
                          const value = candidatesSettings?.selectAll ? activeCandidate[key] : candidatesSettings?.active?.[key] || candidatesSettings?.selected?.[0]?.[key];

                          if (!value) {
                            return null;
                          }

                          return (
                            <div key={key} className="details-container flex items-center mb-1">
                              <div style={{ width: 23 }}>
                                <Icon name={icon} />
                              </div>
                              <p className="mb-0">
                                {key === 'dateOfBirth' ? moment(value).format('YYYY-MM-DD') : value}
                              </p>
                            </div>
                          );
                        })}
                        <StarRating clickable className="mt-3" rate={latestCandidateRating} onClick={onCandidateRate} />
                        {activeCandidate.fromBoostPackage ? (
                          <span className="mt-3 flex items-center">
                            Generated by{' '}
                            <img
                              className="w-6 h-6 ml-2"
                              alt="WeSelect logo"
                              src={`${publicUrl}/images/logo-new.png`}
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <CandidateButtons
                    candidatesFilters={candidatesFilters}
                    candidatesSettings={candidatesSettings}
                    calendarSettings={calendarSettings}
                    position={position}
                    user={user}
                    getCandidateDetails={getCandidateDetails}
                    activeCandidate={activeCandidate}
                  />
                </div>
              </div>
            )}
            {!isMultipleSelected && (
              <>
                <Tags
                  items={activeCandidate?.tags || []}
                  candidateId={parseInt(activeIdInUrl, 10)}
                  callback={getCandidateDetails}
                />
                <LinkedPositions activeCandidate={activeCandidate} />
              </>
            )}
            <CandidateTabs
              singleSelected={!isMultipleSelected}
              activeCandidate={activeCandidate}
              getCandidatesLoading={loading}
            />
          </div>
        </div>
      </Card>
    </Layout>
  );
};

const CandidatesWrapper = () => {
  const [positionAPI] = useAPI('position');
  const position = useSelectorToolkit(({ settings }) => settings.position);
  const candidatesFilter = useSelectorToolkit(({ settings }) => settings.filters.candidates);
  const defaultSorts = useSelectorToolkit(({ settings }) => settings.sorts.candidates);
  const { status, positionId, pipelineStepId } = candidatesFilter;

  const defaultFilters = useMemo(() => {
    const filters = [];

    Object?.keys(candidatesFilter)?.forEach((item) => {
      if (candidatesFilter?.[item] || candidatesFilter?.[item] === false) {
        filters.push({
          key: item,
          condition: item === 'name' ? '@=' : '==',
          value: candidatesFilter?.[item],
        });
      }
    });

    return filters;
  }, [candidatesFilter]);

  const pipelineFilters = useMemo(() => {
    let newFilters = '';

    if (positionId) {
      newFilters = `positionId==${positionId}`;

      if (pipelineStepId) {
        if (newFilters) {
          newFilters += ',';
        }
        newFilters += `pipelineStepId==${pipelineStepId}`;
      }
    }

    return newFilters;
  }, [positionId, pipelineStepId]);

  const mergeFilters = (defaultFilters, newFilters) => {
    if (defaultFilters && newFilters) {
      return `${defaultFilters},${newFilters}`;
    }

    return newFilters || defaultFilters;
  };

  const rejectedPipelinesRef = useRef(null);

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source, ...rest) => {
        const resolvedPositionId = position.positionId || position?.id;
        if (resolvedPositionId) {
          if (!rejectedPipelinesRef.current) {
            const rejectedPipelines = await positionAPI.getPipelineSteps(resolvedPositionId, {
              filters: 'status==Rejected',
            });

            rejectedPipelinesRef.current = rejectedPipelines;
          }
        } else {
          rejectedPipelinesRef.current = null;
        }

        const newFilters = {
          positionId: resolvedPositionId,
        };

        const filters = rest?.[2] || [];

        filters.forEach((item) => {
          newFilters[item.key] = item.value;
        });

        const sort = {
          by: meta?.sorts?.replace('-', ''),
          direction: meta.sorts.includes('-') ? 'desc' : 'asc',
        };

        const requests = [
          getCandidates(
            {
              filter: newFilters,
              page: {
                number: meta.page,
                size: meta.pageSize,
              },
              sort: meta.sorts ? sort : {},
            },
            {
              cancelToken: source.token,
            }
          ),
        ];

        if (resolvedPositionId) {
          requests.push(
            positionAPI.getPipelineSteps(resolvedPositionId, {
              filters: mergeFilters(pipelineFilters, status ? `status==${status}` : null),
            })
          );
        }

        const [data, pipelines] = await axios.all(requests);

        if (pipelines) {
          dispatchToolkit(
            setActivePosition({
              ...position,
              pipelines: pipelines || [],
            })
          );
        }

        dispatchToolkit(
          setCandidatesWithKey({
            key: 'totalCount',
            payload: data?.totalCount,
          })
        );

        return {
          ...data,
          candidates: data?.items,
          rejectedPipelinesRef,
          rejectedPipelines: status !== 'Rejected' ? rejectedPipelinesRef.current : pipelines,
        };
      }}
      defaultFilters={defaultFilters}
      defaultSorts={defaultSorts}
    >
      <CandidateView />
    </ApiRequestProvider>
  );
};

export default CandidatesWrapper;
